<template>
  <section class="login-signup">
    <div class="primary-login">
      <div class="close-icon" @click="closeSlide">
        <img src="../../assets/images/close-icon.svg" alt />
      </div>
      <div class="service-heading">
        <h2 v-if="mode === 'create'">Add Sub Facility</h2>
        <h2 v-if="mode === 'edit'">Edit Sub Facility</h2>
      </div>
      <Form @submit="addNewUser" :validation-schema="schema">
        <div class="category-content">
          <div class="inputs">
            <div class="input-wrapper">
              <select
                placeholder="Select Service"
                class="selectInput"
                v-model="addUser.service"
                disabled
                @change="serviceSelector"
                name="service"
                id="type"
              >
                <option disabled selected value="">Select Service</option>
                <option
                  v-for="category in categoryTypes"
                  :key="category.key"
                  :value="category.key"
                >
                  {{ category.value }}
                </option>
              </select>
              <span
                v-if="errors.service"
                data-v-5e328386=""
                role="alert"
                class="error-msg"
              >
                Service is a required field</span
              >
            </div>
            <div class="input-wrapper">
              <input
                name="subName"
                type="text"
                disabled
                placeholder="Sub Facility Name"
                @input="firstNameInput"
                v-model="addUser.subName"
                required
              />
              <ErrorMessage class="error-msg" name="name" />
            </div>

            <div class="input-wrapper">
              <Field
                name="title"
                type="text"
                @input="titleInput"
                placeholder="Name in English"
                v-model="addUser.title"
                required
              />
              <ErrorMessage class="error-msg" name="title" />
            </div>
            <div class="input-wrapper">
              <Field
                name="name_arr"
                type="text"
                @input="lastNameInput"
                placeholder="Name in Arabic"
                v-model="addUser.name_arr"
                required
              />
              <ErrorMessage class="error-msg" name="name_arr" />
            </div>

            <div class="input-wrapper radio-inpout">
              <label style="text-align: left; padding-bottom: 10px"
                >is it Number?</label
              >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 10px;
                "
              >
                <div style="display: flex; align-items: center; gap: 10px">
                  <p>Yes</p>
                  <input
                    type="radio"
                    :checked="isNumber"
                    @change="isNumberInput"
                    name="Yes"
                    id=""
                  />
                </div>
                <div style="display: flex; align-items: center; gap: 10px">
                  <p>No</p>
                  <input
                    type="radio"
                    :checked="isNotNumber"
                    @change="isNotNumberInput"
                    name=""
                    id=""
                  />
                </div>
                <!-- <input
                    type="checkbox"
                    :checked="isNumber"
                    @change="isNumberInput"
                    name=""
                    id=""
                  /> -->
              </div>
              <!-- <div style="display: flex; justify-content: center">
                  <p>No</p>
                  <input
                    type="checkbox"
                    :checked="isNotNumber"
                    @change="isNotNumberInput"
                    name=""
                    id=""
                  />
                </div> -->
            </div>
          </div>

          <div class="image-upload-box">
            <img v-if="addUser.image" :src="addUser.image" />
            <div class="upload-file">
              <p>Add image</p>
              <label for="inputTag" :class="{ active: errors.file }">
                Add image
                <input
                  id="inputTag"
                  type="file"
                  accept="image/png, image/gif, image/jpeg, image/svg+xml"
                  @change="handleFile"
                />
              </label>
            </div>
          </div>

          <div class="add-btn">
            <button type="submit" :disabled="loading" :onclick="logData">
              {{ !loading ? "Submit" : "Loading..." }}
            </button>
          </div>
        </div>
      </Form>
    </div>
  </section>
</template>

<script>
import { CategoryType } from "@/constants/CategoryType";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "CreateUserModel",
  props: ["user", "mode", "role", "data"],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    let addUser = { service: "" };
    if (this.user) {
      addUser = this.user;
      addUser.service = this.user.service;
      if (this.data.facility) {
        addUser.subName = this.user.name;
        if (this.user.isNumber) {
          this.isNumber = true;
          this.isNotNumber = false;
        } else {
          this.isNumber = false;
          this.isNotNumber = true;
        }
      }
    }
    return {
      errors: {
        subName: false,
        name_arr: false,
        title: false,
        service: false,
        count: false,
      },
      categoryTypes: CategoryType,

      addUser: addUser,
      file: null,
      categories: [],
      isNumber: false,
      isNotNumber: true,
      showPass: false,
      //
      loading: false,
    };
  },
  computed: {
    schema() {
      return yup.object({
        name_arr: yup.string().trim().required().min(3).label("Name in Arabic"),
        title: yup.string().trim().required().min(3).label("Name in English"),
      });
    },
  },
  mounted() {
    if (this.mode === "create") {
      this.addUser = { service: "" };
    }
    if (this.data.facility) {
      let { name, _id, service } = this.data.facility;
      this.addUser.subName = name;
      this.addUser.facility = _id;
      this.addUser.service = service;
      this.isNumber = this.user.isNumber;
      this.isNotNumber = !this.user.isNumber;
    } else {
      this.addUser.subName = this.user?.name;
      this.addUser.facility = this.user?._id;
      this.addUser.service = this.user?.service;
    }
    this.getData();
  },
  methods: {
    firstNameInput() {
      console.log(this.addUser);
      this.errors.subName = false;
    },
    lastNameInput() {
      this.errors.name_arr = false;
    },
    titleInput() {
      this.errors.title = false;
    },
    isNumberInput(e) {
      if (e.target.checked) {
        this.isNumber = true;
        this.isNotNumber = false;
      } else {
        this.isNumber = false;
      }
    },
    isNotNumberInput(e) {
      if (e.target.checked) {
        this.isNumber = false;
        this.isNotNumber = true;
      } else {
        this.isNotNumber = false;
      }
    },
    logData() {
      console.log(this.addUser);
    },

    async getData() {
      try {
        var res = await this.$axios.get("admin/all-category");
        if (res) {
          this.categories = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleFile(event) {
      if (event.target.files && event.target.files[0]) {
        this.addUser.image = URL.createObjectURL(event.target.files[0]);
        let formData = new FormData();
        formData.append("image", event.target.files[0]);

        this.errors.file = false;
        this.file = formData;
      }
    },
    async addNewUser() {
      if (!this.addUser.title) {
        this.errors.title = true;
        return;
      }
      // if (!this.file) {
      //   return;
      // }
      console.log(this.addUser, this.errors);
      if (!this.addUser.subName || !this.addUser.name_arr) {
        this.errors.subName = true;
        this.errors.name_arr = true;

        return;
      }

      if (!this.addUser.title) {
        this.errors.title = true;
        return;
      }
      this.addUser.subName = this.addUser.subName.trim();
      this.addUser.name_arr = this.addUser.name_arr.trim();
      this.addUser.title = this.addUser.title.trim();

      if (!this.addUser.subName) {
        this.errors.subName = true;
        return;
      }

      if (!this.addUser.title) {
        this.errors.title = true;
        return;
      }

      if (!this.addUser.name_arr) {
        this.errors.name_arr = true;
        return;
      }
      this.loading = true;
      var imageUrl = "";
      if (this.file) {
        imageUrl = await this.uploadFiles();
      }
      this.add(imageUrl);
    },
    async add(imageUrl) {
      var payload = this.addUser;
      if (imageUrl) {
        payload.image = imageUrl;
      }
      payload.isNumber = this.isNumber;
      try {
        var res;
        var title;
        payload.facility = this.addUser.facility;
        delete payload.subName;
        delete payload.service;

        if (this.mode === "create") {
          res = await this.$axios.post(`/facility/sub/add`, payload);
          title = "Sub Facility created successfully";
        } else {
          delete payload.__v;

          res = await this.$axios.put(`/facility/sub/edit`, {
            ...payload,
            _id: this.user._id,
          });
          title = "Sub Facility details updated successfully";
        }
        if (res) {
          this.loading = false;
          this.closeSlide();
          this.$swal({
            icon: "success",
            title,
            showConfirmButton: false,
            timer: 3000,
          });
          if (this.data.facility) {
            this.$parent.closeSlide();
          }
          setTimeout(() => {
            this.$emit("reCall", res.data);
            this.closeSlide();
          }, 3000);
        }
      } catch (error) {
        this.loading = false;
        const msg =
          error?.response?.data?.error ||
          error?.message ||
          "Something went wrong";
        this.$swal({
          icon: "error",
          title: msg,
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      }
    },
    async uploadFiles() {
      try {
        const imagesData = await this.$axios.post("user/upload", this.file, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return imagesData.data.url;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    closeSlide() {
      if (this.data.facility) {
        this.$parent.closeSlide();
      }
      this.$emit("close");
    },
  },
  watch: {
    mode: function (newVal) {
      console.log(newVal);
      // watch it
      if (newVal) {
        this.addUser = {};
      }
    },
  },
};
</script>

<style scoped>
.login-signup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #0000004d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.input-wrapper.radio-inpout input[type="radio"] {
  background: transparent;
  box-shadow: none !important;
  width: max-content;
}
.primary-login {
  width: 700px;
  background: #fff;
  padding: 25px;
  border-radius: 20px;
  position: relative;
  overflow: scroll;
}
.primary-login::-webkit-scrollbar {
  display: none;
}
.primary-login {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.close-icon {
  cursor: pointer;
  top: 26px;
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: 99;
}
.service-heading h2 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  font-size: 18px;
}
.category-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.category-content .inputs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.input-wrapper {
  display: flex;
  width: 26%;
  flex-direction: column;
  padding: 5px 12px;
  margin: 15px 8px 8px 8px;
}
.category-content .inputs input {
  outline: none;
  font-size: 12px;
  padding: 15px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 90%;
}

.error-msg {
  padding-top: 6px;
  margin-bottom: 0px;
  font-size: 12px;
  color: red;
  text-align: left;
}

.upload-file input {
  display: none;
}
.upload-file {
  margin: 30px 0px 10px 0px;
  padding: 15px;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  border-radius: 20px;
  line-height: 1.8;
  width: 40%;
}
.upload-file p {
  letter-spacing: 0px;
  color: #393f45;
  font-size: 12px;
  padding-bottom: 20px;
  line-height: 1.8;
}
.upload-file label {
  cursor: pointer;
  padding: 10px 24px;
  background: #febb12;
  color: #fff;
  font-size: 12px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}
.upload-file label.active {
  border: 1px solid red !important;
}
.add-btn {
  margin: 40px 0 10px 0;
  width: 40%;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 32px;
  background: #febb12;
  color: #fff;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}

.image-upload-box {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 25px;
  align-items: center;
}
.image-upload-box img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}
.image-upload-box .upload-file {
  width: 145px;
  height: 90px;
}
p.firstNameError,
p.lastNameError {
  position: absolute;
  left: 45px;
  top: 135px;
  font-size: 10px;
  color: red;
}
p.lastNameError {
  left: 275px;
}

.pass-field {
  position: relative;
}

.pass-field img {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px;
  cursor: pointer;
}
.selectInput {
  padding: 10px;
  border-radius: 27px;
  padding-left: 30px;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
}
/* responsive */
@media (max-width: 479px) and (min-width: 320px) {
  .primary-login {
    width: 84%;
  }
}
</style>
