<template>
  <section class="login-signup facility-mode">
    <div class="primary-login">
      <div class="close-icon" @click="closeSlide">
        <img src="../../../assets/images/close-icon.svg" alt />
      </div>
      <div class="service-heading">
        <h2>Facility Details</h2>
      </div>
      <section class="service-popup">
        <div class="service-details">
          <img v-if="user.image" :src="user.image" />

          <!-- <h3>User Details</h3> -->
          <div>
            <p class="fac-name">Facility Name</p>
            <p>{{ `${user.firstName ?? ""} ${user.name ?? ""}` }}</p>
          </div>
          <div>
            <p class="fac-name">Facility Name in Arabic</p>
            <p>{{ `${user.name_arr ? user.name_arr : "NA"}` }}</p>
          </div>

          <div>
            <p class="fac-name">Service</p>
            <p>
              {{ `${user.service ? user.service : "NA"}` }}
            </p>
          </div>
        </div>
        <table v-if="subList?.length > 0">
          <tr>
            <!-- <th>Sr No.</th> -->

            <th>Name</th>
            <th>Name in Arabic</th>
            <th>Title</th>
            <th>Is it Number</th>
            <th>Service</th>

            <th v-if="isSuperAdmin || user_permissions?.permissions?.edit">
              Status
            </th>
            <th>Action</th>
          </tr>
          <tr v-for="(user, index) in subList" :key="index">
            <!-- <td>{{ user.count }}</td> -->

            <td>{{ user.facility?.name }}</td>
            <td>{{ user.name_arr }}</td>
            <td>{{ user.title }}</td>
            <td>{{ user.isNumber ? "Yes" : "No" }}</td>
            <td>{{ user.facility?.service }}</td>

            <td v-if="isSuperAdmin || user_permissions?.permissions?.edit">
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="user.status == 'active' ? true : false"
                  @click="(e) => toggleActive(user)"
                />
                <span class="slider round"></span>
              </label>
            </td>
            <td>
              <div class="action-btns">
                <img
                  v-if="isSuperAdmin || user_permissions?.permissions?.edit"
                  @click="showEditUserModal(user)"
                  src="../../../assets/images/edit.svg"
                />
              </div>
            </td>
          </tr>
        </table>
      </section>
      <div class="id-image">
        <div class="add-btn">
          <button @click="closeSlide">Close</button>
        </div>
      </div>
    </div>
    <CreateSubFacility
      v-if="createSubModel"
      @close="closeSubModel"
      :user="selectedUser"
      :mode="mode"
      :role="type"
      :data="selectedUser"
    />
  </section>
</template>

<script>
import CreateSubFacility from "../CreateSubFacility.vue";
export default {
  name: "AppUserModel",
  props: ["user", "subList"],
  components: {
    CreateSubFacility,
  },
  data() {
    const placeholder = require("../../../assets/images/placeholder.jpg");
    return {
      placeholder,
      isSuperAdmin: false,
      user_permissions: "",
      selectedUser: {},
      createSubModel: false,
      mode: "edit",
    };
  },
  mounted() {
    console.log(this.subList, "=======sub");
  },
  methods: {
    Suspend() {
      const imagePath = require("../../../assets/images/cancelicon.png");
      this.$swal({
        title: "You want to suspend/unpublish?",
        text: "You want to suspend/unpublish the service?",
        imageUrl: imagePath,
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#FEBB12",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.suspendConfirmed();
        }
      });
    },
    async toggleActive(user) {
      console.log(user, "=======user");
      await this.$axios.put(`facility/sub/edit`, {
        _id: user._id,
        status: user.status == "active" ? "inactive" : "active",
      });

      this.$swal({
        toast: true,
        title: "Facility Status Updated!",
        icon: "success",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    setPermissions() {
      let permissions = this.$store.state?.user?.resources;
      console.log(permissions, "=======permissions");
      this.isSuperAdmin =
        this.$store.state?.user?.role === "SuperAdmin" ? true : false;

      this.user_permissions = permissions?.find(
        (permission) => permission.name == "user"
      );
    },
    async suspendConfirmed() {
      try {
        var res = await this.$axios.put(
          `admin/service/${this.user._id}/un-publish`
        );
        if (res) {
          this.$emit("call");
          this.$swal({
            title: "Suspended",
            text: "Your service has been Suspended successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#FEBB12",
            confirmButtonText: "Done",
            reverseButtons: true,
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Some Thing Went Wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      }
    },
    showEditUserModal(user) {
      this.mode = "edit";
      this.selectedUser = user;
      this.createSubModel = true;
      console.log(this.createSubModel, this.selectedUser);
    },
    Approve() {
      const imagePath = require("../../../assets/images/cancelicon.png");
      this.$swal({
        title: "You want to Approve?",
        text: "You want to Approve the service?",
        imageUrl: imagePath,
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#FEBB12",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.publishedConfirmed();
          console.log("result");
        }
      });
    },
    async publishedConfirmed() {
      try {
        var res = await this.$axios.put(
          `admin/service/${this.user._id}/publish`
        );
        if (res) {
          this.$emit("call");
          this.$swal({
            title: "Approved",
            text: "Your service has been Approved successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#FEBB12",
            confirmButtonText: "Done",
            reverseButtons: true,
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Some Thing Went Wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      }
    },
    closeSlide() {
      this.$parent.$parent.userModel = false;
    },
  },
  watch: {
    "$store.state.user": {
      handler() {
        this.setPermissions();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.login-signup.facility-mode {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #0000004d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.primary-login {
  width: 60%;
  height: 80vh;
  background: #fff;
  padding: 25px 25px 0 25px;
  border-radius: 20px;
  position: relative;
  overflow: scroll;
}
table {
  width: 100%;
  margin: 15px 0 10px 0;
  box-shadow: 0px 0px 39px #00000012;
  border-radius: 11px;
  opacity: 1;
  background: #fff;
}
table th {
  border-bottom: 1px solid #eee;
  padding: 15px;
}
.primary-login::-webkit-scrollbar {
  display: none;
}
.primary-login {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.service-popup {
  overflow-y: scroll;
  padding: 20px;
}
.service-popup::-webkit-scrollbar {
  display: none;
}
.primary-login {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.close-icon {
  cursor: pointer;
  top: 26px;
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: 99;
}
.booking-detail {
  padding: 0 !important;
}
.service-heading h2 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  font-size: 20px;
}
.service-details {
  padding: 25px 0 0px 0;
  max-width: 51%;
  margin: 0 auto;
}
.service-details h3 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 15px;
  padding: 10px 0 10px 0;
}
.service-details div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.service-details div p {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
}
.id-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.id-image div h3 {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
  opacity: 0.7;
}
.id-image div .placeholder-img {
  width: 250px;
  height: 130px;
  background: #eee;
  margin: 15px 0;
  border-radius: 11px;
}
.add-btn {
  width: 50%;
  margin: 20px;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 32px;
  background: #febb12;
  color: #fff;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}
.service-details img {
  width: 100px;
  border-radius: 50%;
  height: 100px;
  object-fit: cover;
}
.action-btns {
  max-width: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.action-btns img {
  width: 40px;
  cursor: pointer;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}
p.fac-name {
  font-weight: 600;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #febb12;
}

input:focus + .slider {
  box-shadow: 0 0 1px #febb12;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* responsive */
@media (max-width: 479px) and (min-width: 320px) {
  .primary-login {
    width: 84%;
  }
}
</style>
