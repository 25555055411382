<template>
  <section class="login-signup">
    <div class="primary-login">
      <div class="close-icon" @click="closeSlide">
        <img src="../../assets/images/close-icon.svg" alt />
      </div>
      <div class="service-heading">
        <h2>Vendor Details</h2>
      </div>
      <section class="service-popup">
        <div class="service-details">
          <div class="icon">
            <img
              class="hotel-icon"
              :src="selectedVendor?.image ? selectedVendor?.image : placeholder"
            />
          </div>
          <div>
            <p>Vendor Name</p>
            <p>{{ selectedVendor.firstName }} {{ selectedVendor.lastName }}</p>
          </div>
          <div>
            <p>Mobile number</p>
            <p>{{ selectedVendor.phone }}</p>
          </div>
          <div>
            <p>Email</p>
            <p>{{ selectedVendor.email }}</p>
          </div>
          <div>
            <p>Address</p>
            <p>{{ selectedVendor.address }}</p>
          </div>
          <div>
            <p>Nationality</p>
            <p>{{ selectedVendor.nationality }}</p>
          </div>
          <div>
            <p>Services</p>
            <p>{{ selectedVendor.numberOfservices }}</p>
          </div>
          <div>
            <p>ID No</p>
            <p>{{ selectedVendor.idNumber }}</p>
          </div>
          <div>
            <p>Commercial ID No</p>
            <p>{{ selectedVendor.commId }}</p>
          </div>
          <div>
            <p>Balance</p>
            <p>{{ selectedVendor.balance }} SAR</p>
          </div>
          <div>
            <p>Vendor Type</p>
            <p>{{ selectedVendor.company ? "Company" : "Host" }}</p>
          </div>
          <div>
            <p>Status</p>
            <p>{{ selectedVendor.status }}</p>
          </div>
          <div>
            <p>Requested At</p>
            <p>
              {{
                moment(selectedVendor.requestedAt).format(
                  "ddd, D MMMM YYYY, h:mm A"
                )
              }}
            </p>
          </div>
          <br />
          <div class="docs">
            <p>ID Photo</p>
            <div v-if="selectedVendor?.uploadIdPhoto != ''" class="documents">
              <img
                class="hotel-icon"
                :src="
                  selectedVendor?.uploadIdPhoto
                    ? selectedVendor?.uploadIdPhoto
                    : IdPlaceholder
                "
              />
            </div>
          </div>
          <div v-if="selectedVendor.company" class="docs">
            <p>Commercial ID&nbsp;Photo</p>
            <div
              v-if="selectedVendor?.commercialIdPhoto != ''"
              class="documents"
            >
              <img
                class="hotel-icon"
                :src="
                  selectedVendor?.commercialIdPhoto
                    ? selectedVendor?.commercialIdPhoto
                    : IdPlaceholder
                "
              />
            </div>
          </div>
        </div>
        <!-- <div class="id-image">
          <div>
            <h3>Commercial ID Image</h3>
            <div class="placeholder-img" v-if="!selectedVendor.file">
              <img src="../../assets/images/placeholder.png" />
              <img src="../../assets/images/placeholder.png" />
            </div>
            <div class="placeholder-img" v-else>
              <img :src="selectedVendor.file" />
            </div>
          </div>
        </div> -->
      </section>
      <div class="id-image">
        <!-- <div class="add-btn">
          <button @click="Approve">Approve</button>
        </div> -->
        <!-- <div class="add-btn">
          <button>Close</button>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
export default {
  name: "VendorDetailModel",
  props: ["selectedVendor"],
  data() {
    const placeholder = require("../../assets/images/placeholder.jpg");
    const IdPlaceholder = require("../../assets/images/placeholder.png");
    return { placeholder, IdPlaceholder };
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    Approve() {
      const imagePath = require("../../assets/images/cancelicon.png");
      this.$swal({
        title: "You want to Approve?",
        text: "You want to Approve the vendor?",
        imageUrl: imagePath,
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#FEBB12",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelConfirmed();
        }
      });
    },
    async cancelConfirmed() {
      try {
        var res = await this.$axios.get(
          `admin/vender/accept/${this.selectedVendor._id}`
        );
        if (res) {
          this.$emit("call");
          this.$swal({
            title: "Approved",
            text: "Your vendor has been Approved successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#FEBB12",
            confirmButtonText: "Done",
            reverseButtons: true,
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Some Thing Went Wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      }
    },
    closeSlide() {
      this.$parent.$parent.vendorModel = false;
    },
  },
};
</script>

<style scoped>
.login-signup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #0000004d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.primary-login {
  width: 500px;
  background: #fff;
  padding: 25px 25px 0 25px;
  border-radius: 20px;
  position: relative;
}
.service-popup {
  height: 400px;
  overflow-y: scroll;
}
.service-popup::-webkit-scrollbar {
  display: none;
}
.primary-login::-webkit-scrollbar {
  display: none;
}
.primary-login {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.close-icon {
  cursor: pointer;
  top: 26px;
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: 99;
}
.service-heading h2 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  font-size: 20px;
}
.service-details {
  padding: 25px 0;
}
.service-details div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.service-details div p {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
}
.id-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.id-image div h3 {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
  opacity: 0.7;
}
.id-image div .placeholder-img {
  width: 250px;
  height: 130px;
  background: #eee;
  margin: 15px 0;
  border-radius: 11px;
}
.add-btn {
  width: 50%;
  margin: 10px;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 32px;
  background: #febb12;
  color: #fff;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}

.icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 110px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.documents {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 90%;
  height: 200px;
  border-radius: 10px;
  background: #f0f2f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.documents img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.docs {
  display: flex;
  flex-direction: column;
}

/* responsive */
@media (max-width: 479px) and (min-width: 320px) {
  .primary-login {
    width: 84%;
  }
}
</style>
