<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>Vendors</h2>
        <p>You can see all the vendors here. Also you can add new Vendors.</p>
      </div>
      <div class="search-area">
        <div class="search-main">
          <div class="search-container">
            <input v-model="search" type="text" placeholder="Search here..." />
            <img src="../assets/images/search.svg" />
          </div>
          <div
            v-if="isSuperAdmin || vendor_permissions?.permissions?.create"
            class="add-btn"
          >
            <button @click="createVendorModelShow">Add Vendor</button>
          </div>
        </div>
      </div>

      <div class="service-container">
        <div class="service-detail">
          <table>
            <tr>
              <th>ID</th>
              <th>Vendor Name</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Address</th>
              <th>Services</th>
              <th>Commercial ID</th>
              <th>Action</th>
            </tr>
            <tr v-for="(vendor, index) in vendorList" :key="index">
              <td>{{ vendor.count }}</td>
              <td>{{ vendor?.firstName?.slice(0, 10) }}</td>
              <td>{{ vendor.phone }}</td>
              <td>{{ vendor.email }}</td>
              <td>{{ vendor?.address?.slice(0, 12) }}...</td>
              <td>{{ vendor.numberOfservices }}</td>
              <td>{{ vendor.commId }}</td>
              <td>
                <div class="action-btns">
                  <img
                    @click="VendorModelShow(vendor)"
                    class="eye-icon"
                    src="../assets/images/eye.svg"
                  />
                  <img
                    v-if="isSuperAdmin || vendor_permissions?.permissions?.edit"
                    @click="editVendor(vendor)"
                    src="../assets/images/edit.svg"
                  />
                  <img
                    v-if="!vendor?.loginProcessing"
                    @click="loginVendor(vendor._id)"
                    class="eye-icon"
                    src="../assets/images/loginIcn.svg"
                    style="height: 16px"
                  />
                  <img
                    v-if="vendor?.loginProcessing"
                    class="eye-icon"
                    src="../assets/images/loading.svg"
                  />
                  <label
                    v-if="isSuperAdmin || vendor_permissions?.permissions?.edit"
                    class="switch"
                  >
                    <input
                      type="checkbox"
                      :checked="vendor.isDeleted == false ? true : false"
                      @click="(e) => toggleActive(e, vendor._id)"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </td>
            </tr>
            <!-- <tr>
              <td>02</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>2</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>
                <div class="view-btn" @click="VendorModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>03</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>3</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>
                <div class="view-btn" @click="VendorModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>04</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>4</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>
                <div class="view-btn" @click="VendorModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>05</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>5</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>
                <div class="view-btn" @click="VendorModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>06</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>6</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>
                <div class="view-btn" @click="VendorModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>07</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>7</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>
                <div class="view-btn" @click="VendorModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>08</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>8</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>
                <div class="view-btn" @click="VendorModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr> -->
          </table>
          <div class="bottom-container">
            <div>
              <p>
                Showing
                {{ pageSelected != 1 ? dataShow * pageSelected - dataShow : 1 }}
                to
                {{
                  vendorList[vendorList.length - 1] &&
                  vendorList[vendorList.length - 1].count
                }}
                of {{ vendorData.length }} entries
              </p>
            </div>
            <div class="service-pagination">
              <paginate
                v-model="pageSelected"
                :page-range="3"
                :margin-pages="2"
                :page-count="pageCount"
                :click-handler="clickCallback"
                :prev-text="'Previous'"
                :next-text="''"
                :container-class="'pagination'"
                :page-class="'page-item'"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
      <VendorDetailModel
        v-if="vendorModel"
        :selectedVendor="selectedVendor"
        @call="close"
      />
      <VendorModel v-if="createVendorModel" @call="close" />
      <EditVendorModel
        v-if="editVendorModel"
        :editData="editData"
        @call="close"
      />
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import VendorDetailModel from "@/components/Models/VendorDetailModel.vue";
import VendorModel from "@/components/Models/VendorModel.vue";
import EditVendorModel from "@/components/Models/EditVendorModel.vue";
import Paginate from "vuejs-paginate-next";

const frontend_url = process.env.VUE_APP_FRONTEND_URL;

export default {
  name: "VendorsView",
  components: {
    DefaultLayout,
    Paginate,
    VendorDetailModel,
    EditVendorModel,
    VendorModel,
  },
  data() {
    return {
      isSuperAdmin: false,
      vendor_permissions: "",
      vendorModel: false,
      createVendorModel: false,
      editVendorModel: false,
      allData: [],
      vendorData: [],
      vendorList: [],
      editData: {},
      //
      pageCount: 0,
      selectedVendor: {},
      total: 0,
      pageSelected: 1,
      //
      dataShow: 10,
      search: "",
    };
  },
  mounted() {
    this.getRequests();
  },
  methods: {
    clickCallback(num) {
      var copyFrom = num * this.dataShow - this.dataShow;
      var copyTo = num * this.dataShow;
      this.vendorList = this.vendorData.slice(copyFrom, copyTo);
    },
    VendorModelShow(vendorObj) {
      this.selectedVendor = vendorObj;
      this.vendorModel = !this.vendorModel;
    },
    async getRequests() {
      try {
        const vendors = await this.$axios.get("admin/all-venders");
        this.total = vendors.data.length;
        //asigning number
        this.vendorData = [];
        for (let index = 0; index < vendors.data.length; index++) {
          const element = vendors.data[index];
          element.count = index + 1;
          this.vendorData.push(element);
        }
        this.pageCount = Math.ceil(this.total / this.dataShow);
        this.allData = [...this.vendorData];
        this.vendorList = this.vendorData.slice(0, this.dataShow);
      } catch (error) {
        console.log(error);
      }
    },
    close() {
      this.getRequests();

      this.selectedVendor = false;
      this.vendorModel = false;
      this.createVendorModel = false;
      this.editVendorModel = false;
      this.getRequests();
      setTimeout(() => {
        this.getRequests();
      }, 1000);
    },

    createVendorModelShow() {
      this.createVendorModel = !this.createVendorModel;
    },

    editVendor(vendor) {
      this.editData = vendor;
      this.editVendorModel = !this.editVendorModel;
    },

    async toggleActive(e, id) {
      this.$swal({
        toast: true,
        title: "Vendor Status Updated!!!",
        icon: "success",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
      });
      if (!e.target.checked) {
        await this.$axios.put(`admin/active/${id}`);
      } else {
        await this.$axios.put(`admin/inactive/${id}`);
      }
    },
    setPermissions() {
      let permissions = this.$store.state?.user?.resources;

      this.isSuperAdmin =
        this.$store.state?.user?.role === "SuperAdmin" ? true : false;

      this.vendor_permissions = permissions?.find(
        (permission) => permission.name == "vendor"
      );
    },
    loginVendor(id) {
      const vendorProcessing = this.vendorList.find((item) => item._id === id);
      vendorProcessing.loginProcessing = true;
      this.$axios
        .post("admin/create-token", {
          userId: id,
        })
        .then((res) => {
          vendorProcessing.loginProcessing = false;
          window.open(
            `${frontend_url}loginvendor?token=${res?.data?.token}`,
            "_blank"
          );
        })
        .catch(() => {
          vendorProcessing.loginProcessing = false;
          this.$swal({
            toast: true,
            title: "Could Not LogIn as a Vendor!",
            icon: "error",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  },
  watch: {
    "$store.state.user": {
      handler() {
        this.setPermissions();
      },
      immediate: true,
    },
    search: function (newVal) {
      const allData = JSON.parse(JSON.stringify(this.allData));
      let filterResults = allData.filter((i) => {
        let result = false;
        if (i.firstName) {
          result =
            result ||
            i.firstName.toLowerCase().match(new RegExp(newVal.toLowerCase()));
        }
        if (i.lastName) {
          result =
            result ||
            i.lastName.toLowerCase().match(new RegExp(newVal.toLowerCase()));
        }
        if (i.email) {
          result =
            result ||
            i.email.toLowerCase().match(new RegExp(newVal.toLowerCase()));
        }
        if (i.phone) {
          result = result || String(i.phone).match(new RegExp(newVal));
        }
        return result;
      });

      if (newVal === "") {
        filterResults = allData;
      }
      this.vendordata = filterResults;

      this.total = filterResults.length;
      for (let index = 0; index < filterResults.length; index++) {
        const element = filterResults[index];
        element.count = index + 1;
      }
      this.pageCount = Math.ceil(this.total / this.dataShow);
      this.vendorList = filterResults.slice(0, this.dataShow);

      console.log(newVal);
    },
  },
};
</script>
<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  display: flex;
  justify-content: flex-end;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}
.service-detail {
  padding: 20px 0 40px 0;
}
.service-detail table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0px 0px 39px #00000012;
  border-radius: 11px;
  opacity: 1;
  background: #fff;
}

.service-detail table th {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding: 15px 8px;
  white-space: nowrap;
}
th:first-child {
  padding: 15px !important;
}
.service-detail table td {
  padding: 15px 8px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-weight: normal;
  color: #9a9a9a;
  white-space: nowrap;
  font-size: 12px;
}
.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.view-btn button {
  border: none;
  outline: none;
  border-radius: 7px;
  opacity: 1;
  background: #febb12;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 50px;
  cursor: pointer;
}
.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-container div p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 12px;
}

.action-btns {
  max-width: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.action-btns img {
  width: 40px;
  cursor: pointer;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #febb12;
}

input:focus + .slider {
  box-shadow: 0 0 1px #febb12;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.search-area {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.search-container {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  padding: 0 20px;
  margin-right: 10px;
  height: 46px;
}

.search-main {
  display: flex;
}
.search-container img {
  width: 15px;
}
.search-container input {
  outline: none;
  border: none;
  /* height: 38px; */
  color: #828282;
  width: 230px;
}
</style>
